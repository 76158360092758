import React, { useState, useRef, useCallback } from "react";
import "./ImageCaptureViewer.css";

const ImageCaptureViewer = ({ onImageCapture }) => {
  const [capturedImage, setCapturedImage] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(true);
  const videoRef = useRef(null);
  const fileInputRef = useRef(null);

  const startCamera = async () => {
    try {
      // Get all available cameras
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter((device) => device.kind === "videoinput");

      // Define optimal constraints for mobile
      const mobileConstraints = {
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          facingMode: { exact: "environment" },
          // Add advanced constraints for better mobile capture
          advanced: [
            {
              // Try to get a smaller zoom level
              zoom: 1,
              // Optimize for taking pictures of documents/business cards
              focusMode: "continuous",
              whiteBalanceMode: "continuous",
              exposureMode: "continuous",
            },
          ],
        },
      };

      // Desktop constraints
      const desktopConstraints = {
        video: {
          width: { ideal: 1280 },
          height: { ideal: 720 },
          facingMode: "user",
        },
      };

      const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
      const constraints = isMobile ? mobileConstraints : desktopConstraints;

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;

        // Wait for video to be ready
        await new Promise((resolve) => {
          videoRef.current.onloadedmetadata = () => {
            resolve();
          };
        });

        // Try to apply ideal aspect ratio
        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        const settings = track.getSettings();

        // Try to set optimal zoom if available
        if (capabilities.zoom) {
          await track.applyConstraints({
            advanced: [{ zoom: capabilities.zoom.min }],
          });
        }

        // Log camera information for debugging
        console.log("Camera capabilities:", capabilities);
        console.log("Camera settings:", settings);
      }
    } catch (err) {
      console.error("Error accessing camera:", err);
      alert(
        "Unable to access camera. Please ensure camera permissions are granted."
      );
    }
  };

  React.useEffect(() => {
    if (isCameraActive) {
      startCamera();
    } else {
      if (videoRef.current?.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
    }
    return () => {
      if (videoRef.current?.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isCameraActive]);

  const handleCapture = useCallback(() => {
    if (videoRef.current) {
      // Create a temporary canvas with the video dimensions
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;

      // Draw the video frame to the canvas
      const ctx = canvas.getContext("2d");
      ctx.drawImage(videoRef.current, 0, 0);

      // Create a second canvas for potential cropping/resizing
      const outputCanvas = document.createElement("canvas");
      const outputCtx = outputCanvas.getContext("2d");

      // Set desired output dimensions (adjust these as needed)
      const maxWidth = 1920;
      const maxHeight = 1080;

      // Calculate new dimensions maintaining aspect ratio
      let outputWidth = canvas.width;
      let outputHeight = canvas.height;

      if (outputWidth > maxWidth) {
        outputHeight = (maxWidth * outputHeight) / outputWidth;
        outputWidth = maxWidth;
      }
      if (outputHeight > maxHeight) {
        outputWidth = (maxHeight * outputWidth) / outputHeight;
        outputHeight = maxHeight;
      }

      // Set canvas size and draw the image
      outputCanvas.width = outputWidth;
      outputCanvas.height = outputHeight;

      // Use better quality settings
      outputCtx.imageSmoothingEnabled = true;
      outputCtx.imageSmoothingQuality = "high";

      // Draw the image with the new dimensions
      outputCtx.drawImage(canvas, 0, 0, outputWidth, outputHeight);

      // Get the final image as a data URL with high quality
      const imageSrc = outputCanvas.toDataURL("image/jpeg", 0.95);

      setCapturedImage(imageSrc);
      setIsCameraActive(false);
      if (onImageCapture) onImageCapture(imageSrc);
    }
  }, [onImageCapture]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "image/jpeg") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCapturedImage(reader.result);
        setIsCameraActive(false);
        if (onImageCapture) onImageCapture(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select a JPEG image file.");
    }
  };

  const handleRetake = () => {
    setCapturedImage(null);
    setIsCameraActive(true);
  };

  // Rest of the component remains the same...
  return (
    <div className="image-capture-container">
      <div className="camera-container">
        {isCameraActive ? (
          <video ref={videoRef} autoPlay playsInline className="camera-feed" />
        ) : capturedImage ? (
          <div className="preview-container">
            <img
              src={capturedImage}
              alt="Captured"
              className="preview-image"
              onClick={() => setIsViewerOpen(true)}
            />
          </div>
        ) : null}
      </div>

      <div className="button-container">
        {!capturedImage ? (
          <>
            <button
              type="button"
              onClick={handleCapture}
              className="capture-button"
            >
              Capture Image
            </button>
            {/* <button
              type="button"
              onClick={() => fileInputRef.current?.click()}
              className="upload-button"
            >
              Upload JPEG
            </button> */}
            <input
              ref={fileInputRef}
              type="file"
              accept="image/jpeg"
              onChange={handleFileUpload}
              className="hidden-input"
            />
          </>
        ) : (
          <>
            <button
              type="button"
              onClick={handleRetake}
              className="retake-button"
            >
              Retake Photo
            </button>
            {/* <button
              type="button"
              onClick={() => setIsViewerOpen(true)}
              className="view-button"
            >
              View Image
            </button> */}
          </>
        )}
      </div>

      {isViewerOpen && (
        <div
          className="image-viewer-overlay"
          onClick={() => setIsViewerOpen(false)}
        >
          <div
            className="image-viewer-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="close-button"
              onClick={() => setIsViewerOpen(false)}
            >
              ×
            </button>
            <img src={capturedImage} alt="Captured" className="viewer-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageCaptureViewer;
