import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
const firebaseConfig = {
  apiKey: "AIzaSyDl2QKMw3FKfI_yl_loD_OFEc2bblnoBkE",
  authDomain: "j6-6-boardwalknissan-atnohe.firebaseapp.com",
  databaseURL: "https://j6-6-boardwalknissan-atnohe.firebaseio.com",
  projectId: "j6-6-boardwalknissan-atnohe",
  storageBucket: "j6-6-boardwalknissan-atnohe.firebasestorage.app",
  messagingSenderId: "423002669430",
  appId: "1:423002669430:web:dd39e4359047c8d9f0fa57"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Firebase services
const storage = getStorage(app);
const database = getDatabase(app);

export { storage, database };
