import React, { useState } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: "user",
};

export const WebcamCapture = () => {
  const [image, setImage] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef, setImage]);

  return (
    <div className="webcam-container">
      <div className="webcam-img">
        {image === "" ? (
          <Webcam
            audio={false}
            height={200}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={220}
            videoConstraints={videoConstraints}
          />
        ) : (
          <img
            src={image}
            alt="Captured"
            onClick={() => setIsViewerOpen(true)}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      <div>
        {image !== "" ? (
          <button
            onClick={(e) => {
              e.preventDefault();
              setImage("");
            }}
            className="webcam-btn"
          >
            Retake Image
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault();
              capture();
            }}
            className="webcam-btn"
          >
            Capture
          </button>
        )}
        {image && (
          <button onClick={() => setIsViewerOpen(true)} className="webcam-btn">
            View Image
          </button>
        )}
      </div>

      {isViewerOpen && (
        <div className="image-viewer-overlay">
          <div className="image-viewer">
            <img src={image} alt="Captured in Viewer" />
            <button
              onClick={() => setIsViewerOpen(false)}
              className="close-viewer-btn"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
